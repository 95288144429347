<template>
    <Panel class="mt-4">
        <template #header>
            <div class="flex flex-row">
                <h3
                    class="inline-flex flex-grow align-middle items-center float-left text-xl text-primary-700 col-span-3"
                >
                    {{ content.prompt }}
                </h3>
            </div>
        </template>
        <template #default>
            <div>
                <LoadingWrapper :isLoading="isLoading">
                    <div v-if="!isUploadSummaryAllowed">
                        Please select files before ingesting your dataset.
                    </div>
                    <UploadSummaryGrid
                        v-else
                        :records="records"
                        @alert="$emit('alert', $event)"
                    />
                </LoadingWrapper>
                <DebugFrame
                    :key="mountKey(`upload-summary-debug`)"
                    id="generic"
                    :debug="frame.isEnabled"
                    :data="frame.data"
                />
            </div>
        </template>
        <template #footer>
            <p class="text-center">{{ content.footer }}</p>
        </template>
    </Panel>
</template>

<script>
    // <!-- API -->
    import {
        defineComponent,
        computed,
        toRefs,
        onMounted,
        onUnmounted,
    } from 'vue';

    // <!-- COMPOSABLES -->
    import {
        useDebugFrame,
        DebugObject,
    } from '@/hooks/reactivity/useDebugFrame';
    import { useMounted } from '@vueuse/core';
    import { useMountKey } from '@/hooks/reactivity/useMountKey';
    import { useUploadSummary } from '~CSVUploader/hooks/input/useUploadSummary';

    // <!-- COMPONENTS -->
    import Panel from '@/components/Panel.vue';
    import LoadingWrapper from '@/components/LoadingWrapper.vue';
    import DebugFrame from '@/components/debug/DebugFrame.vue';
    import UploadSummaryGrid from '~CSVUploader/components/grids/UploadSummaryGrid.vue';

    // <!-- TYPES -->
    import { UploadFormConfig } from '~CSVUploader/hooks/form/useUploadForm';
    import { UploadRecord } from '@/store/types/uploader/state/UploadRecord';
    import { FormNavigationPolicy } from '@/hooks/useNavigationPolicies';
    import collect from 'collect.js';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'UploadSummaryStep',
        components: {
            Panel,
            UploadSummaryGrid,
            LoadingWrapper,
            DebugFrame,
        },
        props: {
            form: {
                /** @type {V.PropType<UploadFormConfig<any, any>>} */
                type: Object,
                required: true,
            },
        },
        emits: ['alert', 'set:policies'],
        setup(props, context) {
            // ==== PROPS ====

            const { form } = toRefs(props);
            const { store } = form.value;
            const { useContent } = useUploadSummary();

            // ==== COMPOSABLES ====

            /**
             * Get the component isMounted tracker
             * for use with the loading components.
             */
            const isMounted = useMounted();

            /**
             * Get the component mount key hack
             * used to force updates to the component state
             * when subscribed to certain actions and mutations.
             */
            const {
                mountKey,
                getNamespacedMountKey,
                subscribeWatchedActions,
                subscribeWatchedMutations,
                resetMountKey,
            } = useMountKey();

            // Get the dynamic content.
            const content = useContent(store, {
                mountKey,
            });

            // ==== COMPUTED PROPERTIES ====

            /** @type {V.ComputedRef<Map<String, UploadRecord>>} */
            const records = computed(() => {
                const _ = mountKey.value;
                return store.api.state.uploader.data.records;
            });

            /**
             * Are datasets being ingested?
             */
            const isIngesting = computed(() => {
                const _ = mountKey.value;
                const records = store.api.state.uploader.data.records;
                const whereIngesting = collect(
                    [...records].filter(([_, r]) => r.isDatasetBatchIngesting)
                );
                return whereIngesting.isNotEmpty();
            });

            /**
             * Are all datasets uploaded?
             */
            const isEveryRecordIngested = computed(() => {
                const _ = mountKey.value;
                const records = store.api.state.uploader.data.records;
                const whereIngested = collect(
                    [...records].filter(([_, r]) => r.isDatasetBatchIngested)
                );
                return (
                    whereIngested.isNotEmpty() &&
                    whereIngested.count() === records.size
                );
            });

            /**
             * Prepare a loading wrapper conditional.
             */
            const isLoading = computed(() => {
                const _ = mountKey.value;
                const isNotReady = isMounted.value !== true;
                return isNotReady;
            });

            /**
             * Is the file selection set empty?
             */
            const isFileSelectionEmpty = computed(() => {
                const _ = mountKey.value;
                const records = store.api.state.uploader.data.records;
                const whereFileSelected = collect(
                    [...records].filter(([_, r]) => r.isFileSelected)
                );
                return whereFileSelected.isEmpty();
            });

            /**
             * Is the location selector visible?
             */
            const isUploadSummaryAllowed = computed(() => {
                const _ = mountKey.value;
                return !isFileSelectionEmpty.value;
            });

            // ==== NAVIGATION POLICIES ====

            const isSubmit = {
                visible: true,
                allowed: computed(() => {
                    const _ = mountKey.value;
                    // NOTE: Restart can be triggered when:
                    // - The form is not loading.
                    // - The form is not ingesting.
                    // - All records have been ingested.
                    return (
                        !isLoading.value &&
                        !isIngesting.value &&
                        isEveryRecordIngested.value
                    );
                }),
            };

            const submit = FormNavigationPolicy.prepare()
                .type.asSubmit()
                .label.exact('Restart')
                .visible.exact(isSubmit.visible)
                .enabled.withComputed(isSubmit.allowed)
                .action.onClick(async () => {
                    console.dir(isSubmit);
                    form.value.handlers.onShowConfirmDeleteModal(
                        'Start New Upload?',
                        'This will discard your current summary.',
                        'Restart'
                    );
                    // await store.restartWorkflow();
                })
                .create();

            const isReset = {
                visible: computed(() => {
                    const _ = mountKey.value;
                    return !isIngesting.value;
                }),
                allowed: computed(() => {
                    const _ = mountKey.value;
                    return !isLoading.value;
                }),
            };

            const reset = FormNavigationPolicy.prepare()
                .type.asReset()
                .label.exact('Back')
                .visible.withComputed(isReset.visible)
                .enabled.withComputed(isReset.allowed)
                .action.onClick(async () => {
                    console.dir(isReset);
                    await store.goToPreviousStep(isReset.allowed.value);
                })
                .create();

            // ==== LIFECYCLE (onMounted) ====

            subscribeWatchedActions(store.api.store, [`*`]);
            subscribeWatchedMutations(store.api.store, [`*`]);

            onMounted(async () => {
                context.emit('set:policies', { submit, reset });
            });

            // ==== LIFECYCLE (onUnmounted) ====

            onUnmounted(() => {
                isMounted.value = false;
                resetMountKey();
            });

            // DEBUG
            /**
             * Computed debug frame.
             */
            const frame = computed(
                () => {
                    const _ = mountKey.value;
                    /** @type {Map<String, UploadRecord>} */
                    const records = store.api.state.uploader.data.records;
                    const selectedRecords = [...records.values()].map((r) => {
                        const {
                            flags,
                            status,
                            file,
                            location,
                            suggestedLocation,
                            mappingProfile,
                            suggestedMappingProfile,
                            batch,
                        } = r ?? {};
                        const { name, size, type } = file.value ?? {};
                        return {
                            id: name,
                            file: {
                                name,
                                type,
                                size: `${size} bytes`,
                            },
                            flags: [...flags].join(','),
                            status: [...status].join(','),
                            location: {
                                transient: location?.transient,
                                persisted: location?.persisted,
                                suggested: suggestedLocation,
                            },
                            profile: {
                                ...mappingProfile,
                                suggested: suggestedMappingProfile,
                            },
                            batch,
                        };
                    });
                    const ingestingRecords = [...records.values()].filter(
                        (r) => r.isDatasetBatchIngesting
                    );
                    const ingestedBatches = [...records.values()]
                        .filter((r) => r.isDatasetBatchIngested)
                        .map((r) => r.batch.value);

                    // Create current step structure.
                    const { id, label, description } =
                        store.api.state.uploader.workflow.currentStep;
                    const currentStep = {
                        id,
                        label,
                        description,
                    };

                    // Prepare data.
                    const data = [
                        DebugObject.create('Current Step', currentStep),
                        DebugObject.create('Summary', {
                            total: `${selectedRecords.length} record(s).`,
                            ingesting: `${ingestingRecords.length} record(s).`,
                            ingested: `${ingestedBatches.length} record(s).`,
                        }),
                        ...ingestedBatches.map((b) => {
                            DebugObject.create(`Dataset Batch`, b);
                        }),
                    ];

                    // Return new frame instance.
                    return useDebugFrame({
                        startHidden: isLoading.value,
                        data,
                    });
                },
                {
                    // onTrack(e) {
                    //     debugger;
                    // },
                    // onTrigger(e) {
                    //     debugger;
                    // },
                }
            );

            /** Expose to the Options API. */
            return {
                // REACTIVITY
                isLoading,
                isMounted,
                mountKey: getNamespacedMountKey,

                // PROPS
                records,

                // DEBUG
                frame,

                // STEP CONTENT
                content,

                // STEP CONDITIONALS
                isFileSelectionEmpty,
                isUploadSummaryAllowed,
                isEveryRecordIngested,
                isIngesting,
            };
        },
    });
</script>
