// <!-- API -->
import { computed } from 'vue';
import { StepContent } from '~CSVUploader/hooks/workflow/useStepContent';

// <!-- TYPES -->
import { UploadStore } from '~CSVUploader/hooks/store/useUploadStore';

/**
 * Get the content status.
 * @param {Number} total
 * @param {Number} ingesting
 * @param {Number} ingested
 */
const getContentStatus = (total, ingesting, ingested) => {
    // ==== STATUS ====
    const status = new Set();

    // ==== COMPLETE ====
    if (ingested > 0) {
        status.add(`Ingested ${ingested} dataset(s).`);
    }

    // ==== REMAINING ====
    if (ingesting > 0) {
        status.add(`Ingesting ${ingested} dataset(s)...`);
    }

    // ==== COUNT ====
    if (total === 0) {
        status.clear();
        status.add(`No dataset(s) to ingest. Please select files for upload.`);
    }
    if (total === ingested) {
        status.clear();
        status.add(`All datasets have been ingested.`);
    }

    // ==== EXPOSE ====
    return [...status].join(' ');
};

/**
 * Handle reactivity for the upload summary step.
 */
export const useUploadSummary = () => {
    /**
     * Create a dynamic computed content object.
     * @param {UploadStore} store
     * @param {Object} props
     * @param {V.Ref<Number>} props.mountKey Dependency tracking variable.
     * @returns {V.ComputedRef<StepContent>}
     */
    const useContent = (store, { mountKey }) => {
        const $header = `Upload Summary`;
        const $prompt = `Review ingestion progress...`;
        return computed(() => {
            // ts-ignore
            const _ = mountKey.value;
            const records = store.api.state.uploader.data.records;
            const ingesting = [...records.values()].filter(
                (r) => r.isDatasetBatchIngesting
            );
            const ingested = [...records.values()].filter(
                (r) => r.isDatasetBatchIngested
            );
            return StepContent.create()
                .setHeader($header)
                .setPrompt($prompt)
                .setFooter(
                    getContentStatus(
                        records.size,
                        ingesting.length,
                        ingested.length
                    )
                );
        });
    };

    // EXPOSE
    return {
        useContent,
    };
};

export default useUploadSummary;
